import React from "react"
import { Link } from "gatsby"

import Layout from "../layouts/pt"
import SEO from "../components/SEO"
import Metomic from "../components/Metomic"
import SplashScreens from "../components/SplashScreens"
import Header from "../components/Header.pt"

const TermsOfUsePage = (props) => (
  <Layout location={props.location}>
    <Metomic />
    <SEO
      title="Termos de Uso | Jubarte Design"
      locale="pt"
      altLocale="en"
      pathname="/terms-of-use/"
      description="Confira nossos Termos de Uso para entender todos os serviços da Jubarte Design. Entenda o que significa design gráfico e web ilimitados"
    />
    <SplashScreens />
    <Header showLanguageSelector />
    <div className="position-relative text-center u-space-3-top">
      <div className="container u-space-3-top u-space-2-top--md u-space-0-bottom">
        <div className="w-md-80 w-lg-60 text-center mx-auto form-search-wrapper">
          <h1 className="display-4 font-size-48--md-down jd-h2-titles">
            <span className="font-weight-bold">Termos </span>de Uso
          </h1>
        </div>
      </div>
    </div>
    <article className="jd-terms">
      <div className="container u-space-1-top">
        <div className="w-lg-90 mx-auto">
          <div className="mb-4">
            <span className="text-muted">Data Efetiva: 25 de Maio de 2020</span>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Introdução</h2>
            <p>
              Bem-vindo à <b>Jubarte Design!</b> Como você acabou de clicar em nossos Termos de Uso, faça uma pausa,
              pegue um café e leia atentamente as seguintes páginas. Deve levar aproximadamente 30 minutos.
            </p>
            <p>
              Estes Termos de Uso ("<b>Termos</b>", "<b>Termos de Serviço</b>") são um contrato entre você ("
              <b>Cliente</b>", "<b>seu</b>", "<b>seus</b>", "<b>usuários</b>", "<b>usuários registrados</b>") e nós ("
              <b>Jubarte Design</b>", "<b>Empresa</b>", "<b>nós</b>", "<b>nosso</b>") e regem o seu uso das nossas
              páginas web, localizadas em
              <a href="https://jubarte.design" rel="nofollow" className="jd-terms-link">
                https://jubarte.design
              </a>{" "}
              (“<b>Site</b>”) e todos os subdomínios e subdiretórios das mesmas, e a Assinatura ou outros serviços que
              prestamos ("<b>Serviço</b>") operados por nós a partir dos nossos escritórios no Canadá.
            </p>
            <p>
              Este Termos de Uso representam um contrato legal, portanto, se você estiver firmando este contrato em nome
              de uma empresa, agência governamental, organização ou outra pessoa jurídica, você declara que tem
              autoridade para vincular tal entidade a estes Termos. (Neste caso, "<b>você</b>" e "<b>seu</b>" passam a
              se referir à entidade).
            </p>
            <p>
              Não garantimos que os materiais do Site sejam apropriados ou disponíveis para uso em outros locais, e o
              acesso a eles a partir de territórios onde seu conteúdo seja ilegal é proibido. Aqueles que optarem por
              acessá-los de locais fora do Canadá são responsáveis pelo cumprimento de todas as leis aplicáveis.
            </p>
            <p>
              Nossa Política de Privacidade também rege seu uso do nosso Site e Serviço e explica como coletamos,
              protegemos e divulgamos informações que resultam do seu uso de nossas páginas na Internet. Por favor,
              leia-a{" "}
              <Link to="/pt/privacy-policy/" className="jd-terms-link">
                aqui
              </Link>
              .
            </p>
            <p>
              Seu acordo conosco inclui estes Termos e nossa Política de Privacidade ("<b>Contrato</b>"). Ao utilizar o
              Serviço e acessar o Site de qualquer maneira, você reconhece que leu e entendeu o Contrato, e concorda em
              ficar vinculado a eles. Se você não concorda com (ou não pode cumprir) o Contrato, então você não pode
              usar o Serviço, mas por favor nos informe enviando um{" "}
              <a
                href="mailto:contact@jubarte.design?subject=Discordância com Termos de Uso"
                className="jd-terms-mailto"
              >
                e-mail
              </a>{" "}
              para que possamos tentar encontrar uma solução.
            </p>
            <p>
              Estes Termos se aplicam a todos os visitantes, usuários e outros que desejam acessar ou usar o Serviço.
            </p>
            <p>Obrigado por ser responsável.</p>
          </div>

          <div className="mb-4">
            <h2 className="h5 mb-3">Comunicações</h2>
            <p>
              Ao criar uma Conta no nosso Serviço, você concorda em receber boletins informativos, materiais de
              marketing ou promocionais e outras informações que possamos enviar por e-mail. No entanto, você pode optar
              por não receber nenhuma, ou todas essas comunicações de nós, seguindo o link de cancelamento de Assinatura
              em cada e-mail enviado, alterando suas preferências de e-mail na página de gerenciamento de sua conta ou
              enviando um{" "}
              <a
                href="mailto:contact@jubarte.design?subject=Deixar de receber Comunicações"
                className="jd-terms-mailto"
              >
                e-mail
              </a>
              .
            </p>
          </div>

          <div className="mb-4">
            <h2 className="h5 mb-3">Utilização do Serviço</h2>
            <p>
              As características do Serviço são definidas pelo tipo de plano (coletivamente, "<b>Planos</b>") que você
              assinar, entretanto todos os Planos oferecem pedidos e revisões ilimitadas de serviço de design gráfico. O
              Plano Oceano é o único plano que também inclui pedidos ilimitados de serviços de web design. Cada Plano
              tem limitações definidas no número de marcas, usuários registrados, dias para entrega, designer ou web
              designer dedicados e gerente de conta dedicado. Os detalhes de cada Plano estão disponíveis no Site.
            </p>
            <p>
              Uma Assinatura é uma medida que representa o atendimento das suas necessidades ou demandas priorizadas,
              através da nossa equipe e plataforma, dentro dos prazos definidos para cada Plano contratado. Embora
              aceitemos pedidos e revisões ilimitadas com uma Assinatura, nosso volume e velocidade de entrega dependem
              de muitos fatores, como por exemplo: (i) o tipo de Plano selecionado para a Assinatura; (ii) o volume de
              pedidos; (iii) e a complexidade dos pedidos.
            </p>
            <p>
              Não garantimos a quantidade de trabalho que podemos entregar dentro de um dia útil para uma Assinatura,
              entretanto, você pode aumentar o volume e a velocidade de entrega com a adição de mais Assinaturas à sua
              conta.
            </p>
            <p>
              Sujeito ao seu cumprimento deste Contrato, bem como à sua Assinatura de um Plano aplicável e ao nosso
              recebimento pontual de seu(s) pagamento(s), colocaremos o Serviço aplicável à sua disposição durante o
              prazo para o qual você assinar. Faremos esforços comercialmente razoáveis para tornar o Serviço
              disponível, sujeito à existência de tempo de inatividade programada e a qualquer manutenção emergencial
              não programada. Nós podemos modificar, substituir ou descontinuar o Serviço a qualquer momento, por
              qualquer motivo, sem aviso prévio.
            </p>
            <p>
              Nós fazemos o nosso melhor para minimizar quaisquer erros. Entretanto, devido à natureza dos projetos
              criativos, não podemos garantir que todos os arquivos entregues estarão 100% livres de erros. Quando
              entregamos um projeto de design ("<b>Projeto de Design</b>") a você, você concorda em revisar todos os
              arquivos por quaisquer erros ou omissões e nos notificar se alguma alteração ou correção for necessária
              dentro de sete (7) dias do recebimento. Faremos nosso melhor para apressar as revisões para corrigir
              quaisquer erros que você nos notifique durante este período de tempo. Após esse período de tempo se você
              não solicitar nenhuma alteração, nossa plataforma marcará automaticamente o Projeto de Design como
              concluído, entretanto, se você nos notificar de qualquer erro após esse período de tempo, não somos
              obrigados a, mas pretendemos tentar trabalhar com você para fazer correções.
            </p>
            <p>
              Você pode usar o Serviço para qualquer número de projetos e escopo que você tenha assinado sob o Plano
              aplicável. O uso do Serviço não é recomendado para projetos prazos limitados, mas faremos o nosso melhor
              para trabalhar com você para acomodar quaisquer itens prioritários e seus prazos.
            </p>
            <p>
              Você é responsável pela obtenção e manutenção de todos os equipamentos e serviços de telecomunicações,
              banda larga e informática necessários para acessar e utilizar o Serviço e pelo pagamento de todas as taxas
              relacionadas ao mesmo.
            </p>
            <p>
              Sujeito ao seu contínuo cumprimento deste Contrato, incluindo o pagamento pontual dos valores devidos, nós
              concedemos a você uma licença limitada, revogável, não exclusiva, não publicável, não transferível para
              acessar e usar o Serviço para seus propósitos comerciais internos.
            </p>
            <p>
              Você é, e será, o único e exclusivo proprietário de todos os direitos, títulos e interesses em e sobre os
              resultados de um Projeto de Design, incluindo todos os direitos de propriedade intelectual do mesmo. Nós
              concordamos que com relação a quaisquer produtos que possam ser qualificados como "trabalho feito no curso
              do emprego" por nossa equipe de designers, conforme definido na Lei de Direitos Autorais do Canadá (R.S.C.
              1985, c, C-42), tais produtos são considerados um "trabalho feito no curso do emprego" para você. Na
              medida em que quaisquer produtos não constituam um "trabalho feito no curso do emprego", nós
              irrevogavelmente atribuímos a você todos os direitos, títulos e interesses em todo o mundo sobre e para os
              produtos, incluindo todos os direitos de propriedade intelectual. Não obstante o acima exposto, os termos
              desta seção estão sujeitos à sua conformidade com este Contrato e ao pagamento integral dos valores
              devidos aplicáveis.
            </p>
            <p>
              Nós podemos utilizar certos materiais pré-existentes para fornecer o Serviço a você. Nós e nossos
              licenciadores (“<b>Licenciadores</b>”) somos, e continuaremos a ser, os únicos e exclusivos proprietários
              de todos os direitos, títulos e interesses em e sobre quaisquer materiais pré-existentes. Nós concedemos a
              você uma licença perpétua, mundial, limitada, livre de royalties, intransferível e não-publicável para
              usar, exibir e distribuir quaisquer materiais pré-existentes na medida em que sejam incorporados,
              combinados ou de outra forma necessários para o uso do Serviço ou dos produtos a serem entregues. Nós
              expressamente reservamos todos os outros direitos sobre os materiais pré-existentes, conforme definido na
              seção Conteúdo Licenciado abaixo.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Compras</h2>
            <p>
              Se você deseja adquirir qualquer produto ou serviço disponibilizado através do Serviço ("<b>Compra</b>"),
              você pode ser solicitado a fornecer certas informações relevantes à sua Compra, incluindo, sem limitação,
              o número do seu cartão de crédito, a data de vencimento do seu cartão de crédito, seu endereço de cobrança
              e correspondência.
            </p>
            <p>
              Você representa e garante que: (i) você tem o direito legal de usar qualquer cartão de crédito ou outra
              forma de pagamento em conexão com qualquer Compra; e que (ii) as informações que você nos fornecer são
              verdadeiras, corretas e completas.
            </p>
            <p>
              Nós podemos empregar o uso de serviços de terceiros com a finalidade de facilitar o pagamento e a
              conclusão de Compras. Ao enviar suas informações, você nos concede o direito de fornecer as informações a
              esses terceiros, sujeito à nossa Política de Privacidade.
            </p>
            <p>
              Nós nos reservamos o direito de recusar ou cancelar seu pedido a qualquer momento por motivos que incluem,
              entre outros:
            </p>
            <p className="jd-terms-list-item">(a) disponibilidade do produto ou serviço;</p>
            <p className="jd-terms-list-item">(b) erros na descrição ou preço do produto ou serviço;</p>
            <p className="jd-terms-list-item">(c) erro em seu pedido;</p>
            <p className="jd-terms-list-item">(d) ou outros motivos.</p>
            <p>
              Nós nos reservamos o direito de recusar ou cancelar seu pedido e encerrar sua conta no caso de não
              pagamento de valores devidos a nós ou se houver suspeita de fraude, transação não autorizada ou ilegal.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Assinaturas</h2>
            <p>
              Algumas partes do Serviço são cobradas por Assinatura ("<b>Assinatura(s)</b>"). Você será cobrado com
              antecedência de forma periódica e recorrente ("<b>Ciclo de Cobrança</b>"). Os ciclos de cobrança são
              estabelecidos de forma mensal, trimestral ou anual, dependendo do tipo de Plano que você selecionar ao
              adquirir uma Assinatura.
            </p>
            <p>
              Antes que tenhamos qualquer obrigação de prestar o Serviço, você deve pagar a Assinatura (bem como os
              impostos aplicáveis) integralmente, nos valores e para o Ciclo de Cobrança especificados durante o
              registro, conforme atualizados (prospectivamente, não retroativamente) por nós de tempos em tempos.
            </p>
            <p>
              Ao final de cada Ciclo de Cobrança, sua Assinatura será renovada automaticamente sob exatamente as mesmas
              condições, a menos que você a cancele ou a Jubarte Design a cancele. Você pode cancelar a renovação da sua
              Assinatura através da página de gerenciamento da sua conta no Site ou entrando em contato com a equipe de
              suporte ao cliente da Jubarte Design.
            </p>
            <p>
              Uma forma de pagamento válida, incluindo cartão de crédito, é necessária para processar o pagamento da sua
              Assinatura e impostos aplicáveis. Você deve fornecer à Jubarte Design informações de cobrança precisas e
              completas, incluindo nome completo, endereço, estado, CEP, número de telefone e uma forma de pagamento
              válida. Ao enviar tais informações de pagamento, você automaticamente autoriza a Jubarte Design a cobrar
              todas as taxas de Assinatura e impostos aplicáveis incorridos através de sua conta em qualquer um desses
              instrumentos de pagamento.
            </p>
            <p>
              Caso a cobrança recorrente automática não ocorra por qualquer motivo, a Jubarte Design emitirá uma fatura
              eletrônica indicando que você deverá realizar o pagamento manualmente, dentro de um determinado prazo, com
              o pagamento integral correspondente ao período de cobrança indicado na fatura.
            </p>
            <p>
              Os valores vencidos acumularão juros mensais à taxa de 1,5% do saldo então não pago, ou à taxa máxima
              permitida por lei, o que for menor. Nós nos reservamos o direito de encerrar sua conta no caso de não
              pagamento dos valores devidos a nós.
            </p>
            <p>
              Todos os valores devidos nos termos deste Contrato são não canceláveis e não reembolsáveis, exceto
              conforme especificamente previsto na seção Reembolsos abaixo.
            </p>
            <p>
              Esperamos que você esteja satisfeito com nosso Serviço. Se, por qualquer razão, você não estiver, você
              pode cancelar sua Assinatura conosco a qualquer momento. Após o cancelamento, você continuará a ter acesso
              ao Serviço e aos seus arquivos de projeto até o final do seu atual prazo de Assinatura já pago. Uma vez
              terminado o prazo de Assinatura após o cancelamento, você não terá mais acesso ao Serviço e poderá perder
              o acesso aos seus arquivos de design através do Site.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Teste Gratuito</h2>
            <p>
              A Jubarte Design pode, a seu exclusivo critério, oferecer uma Assinatura com teste gratuito por um período
              limitado de tempo ("<b>Teste Grátis</b>"). Você pode ser solicitado a inserir suas informações de cobrança
              para se inscrever para o Teste Grátis.
            </p>
            <p>
              Se você digitar suas informações de cobrança ao se inscrever para o Teste Grátis, você não será cobrado
              pela Jubarte Design até que prazo do Teste Grátis expire. No último dia do período de Teste Grátis, a
              menos que você cancele sua Assinatura, você será automaticamente cobrado as taxas de Assinatura aplicáveis
              para o tipo de Assinatura que você selecionou.
            </p>
            <p>
              A qualquer momento e sem aviso prévio, a Jubarte Design se reserva o direito de (i) modificar os Termos de
              Uso da oferta de Teste Grátis, ou (ii) cancelar tal oferta de Teste Grátis.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Testes Beta</h2>
            <p>
              De tempos em tempos e a nosso exclusivo critério, podemos iniciar Testes Beta. Podemos implementar,
              executar, suspender ou encerrar um Teste Beta (“<b>Teste(s) Beta</b>”) a qualquer momento sem aviso
              prévio, e não garantimos que um produto testado, serviço, modificação, atualização ou outro ajuste no
              Site, Serviço ou meio de prestação do serviço, se tornará parte do Serviço.
            </p>
            <p>
              Podemos entregar (e subsequentemente cessar a entrega) um Teste Beta a todos ou qualquer subconjunto de
              titulares de Contas Jubarte Design a nosso critério, sem permissão prévia, a qualquer momento. Se
              decidirmos incorporar um produto ou serviço testado na plataforma Jubarte Design, poderemos ajustar nossos
              preços de acordo. A Jubarte Design não se responsabiliza por qualquer consequência resultante ou
              relacionada à sua participação em um Teste Beta.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Ajustes de Preços</h2>
            <p>
              A Jubarte Design, a seu exclusivo critério e a qualquer momento, poderá modificar os preços das
              Assinaturas. Qualquer alteração no preço da Assinatura entrará em vigor no final do Ciclo de Cobrança
              então vigente.
            </p>
            <p>
              Nós lhe forneceremos um aviso prévio razoável de qualquer mudança nos preços de Assinatura para lhe dar a
              oportunidade de encerrar sua Assinatura antes que tal mudança se torne efetiva. Nós não somos obrigados a
              notificá-lo sobre promoções temporárias ou reduções nos preços.
            </p>
            <p>
              O seu uso continuado do Serviço após a entrada em vigor da alteração nos preços de Assinatura constitui a
              sua concordância para pagar a Assinatura modificada.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Reembolsos</h2>
            <p>
              Emitimos reembolsos para Assinaturas em até 14 (quatorze) dias após a compra original da Assinatura. Após
              esses quatorze dias, os reembolsos não são possíveis em nenhuma circunstância, incluindo o tempo não
              utilizado.
            </p>
            <p>
              Podemos recusar um pedido de reembolso se, a nosso exclusivo critério, encontrarmos evidência de fraude,
              abuso de reembolso ou outro comportamento manipulador.
            </p>
            <p>Promoções, atualizações e ajustes em planos existentes não são reembolsáveis.</p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Concursos, Sorteios e Promoções</h2>
            <p>
              Quaisquer concursos, sorteios ou outras promoções (coletivamente, "<b>Promoções</b>") disponibilizadas
              através do Serviço podem ser regidas por regras separadas destes Termos de Uso. Se você participar de
              qualquer Promoção, por favor, reveja as regras aplicáveis, bem como a nossa Política de Privacidade. Se as
              regras de uma Promoção entrarem em conflito com estes Termos de Uso, as regras das Promoções serão
              aplicadas.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Conteúdo do Cliente</h2>
            <p>
              Nosso Serviço permite que você envie, vincule, armazene, compartilhe e disponibilize certas informações,
              textos, esboços, gráficos, fotos, áudios, vídeos, fontes, desenhos ou outro material ("
              <b>Conteúdo do Cliente</b>"). Você é responsável pelo conteúdo que submeter no ou através do Serviço,
              incluindo sua legalidade, confiabilidade e qualidade.
            </p>
            <p>
              Ao submeter Conteúdo do Cliente no ou através do Serviço, você nos concede uma licença mundial, livre de
              royalties e não exclusiva para acessar e usar tal conteúdo para fornecer o Serviço. Você também garante
              que: (i) o Conteúdo do Cliente é seu (você o possui) e/ou você tem o direito de usá-lo e o direito de nos
              conceder os direitos e licença conforme previsto nestes Termos, e (ii) que o envio de Conteúdo do Cliente
              no ou através do Serviço não viola os direitos de privacidade, direitos de publicidade, direitos autorais,
              direitos contratuais ou quaisquer outros direitos de qualquer pessoa ou entidade.
            </p>
            <p>Reservamo-nos o direito de rescindir a conta de qualquer pessoa que viole um direito autoral.</p>
            <p>
              Nós não assumimos nenhuma responsabilidade pelo Conteúdo do Cliente que você ou qualquer terceiro submete
              no ou através do Site.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Conteúdo Licenciado</h2>
            <p>
              Enquanto você é, e será, o único e exclusivo proprietário de todos os direitos, títulos e interesses em
              seu Projeto de Design, o conteúdo que nós licenciamos para você ("<b>Conteúdo Licenciado</b>"), incluindo
              esboços, fotos, áudios, vídeos, fontes, desenhos e escrita quando incorporado ao seu Projeto de Design
              está sujeito à licença descrita nesta seção. Nenhum direito é concedido a você a não ser o aqui
              expressamente estabelecido.
            </p>
            <p>
              Sujeito ao cumprimento deste Contrato e ao pagamento integral dos valores devidos, nós concedemos a você
              um direito revogável, de abrangência mundial, não exclusivo, intransferível, livre de royalties e licença
              para o Conteúdo Licenciado para seu uso pessoal ou profissional, enquanto em uso no seu Projeto de Design.
            </p>
            <p>
              Exceto conforme expressamente permitido por nós, você concorda em não estocar, direta ou indiretamente,
              vender, alugar, sublicenciar, distribuir, copiar, reproduzir, republicar, fazer engenharia reversa,
              baixar, extrair dados ou modificar qualquer Conteúdo Licenciado disponibilizado através do serviço de
              Banco de Imagens fornecido em nosso Site.
            </p>
            <p>
              Na medida em que nós licenciamos o Conteúdo Licenciado de qualquer terceiro, incluindo, mas não se
              limitando aos serviços de Banco de Imagens Unsplash, DepositPhotos e GettyImages ("<b>Licenciadores</b>"),
              você concorda em cumprir com a licença do terceiro em questão. Exceto com a nossa permissão por escrito,
              você não pode:
            </p>
            <p className="jd-terms-list-item">
              (a) vender, revender, alugar, arrendar, sublicenciar, ceder, conceder um direito de garantia ou de outra
              forma transferir qualquer parte de seus direitos de uso do Conteúdo Licenciado, além de um design
              preparado por nós ou como parte de um produto de design para seu uso pessoal;
            </p>
            <p className="jd-terms-list-item">
              (b) usar o Conteúdo Licenciado de forma pornográfica, difamatória ou de outra forma ilegal;
            </p>
            <p className="jd-terms-list-item">
              (c) representar falsamente que você é o criador original de qualquer Conteúdo Licenciado;
            </p>
            <p className="jd-terms-list-item">
              (d) alterar, adaptar, traduzir, converter, modificar ou fazer quaisquer trabalhos derivados de qualquer
              Conteúdo Licenciado;
            </p>
            <p className="jd-terms-list-item">
              (e) utilizar Conteúdo Licenciado de qualquer forma que permita que outros façam download, extraiam ou
              redistribuam Conteúdo Licenciado como um arquivo ou trabalho autônomo.
            </p>
            <p>
              Nossos Licenciadores e nós mantemos a propriedade sobre o Conteúdo Licenciado que é licenciado a partir
              deles, seja baixado através de nossos serviços de Banco de Imagens ou incorporado ao seu Projeto de
              Design. Nós nos reservamos o direito de rescindir, revogar ou retirar todas as licenças em caso de não
              cumprimento de quaisquer disposições deste Contrato. No caso de qualquer rescisão, você não terá mais
              direito de fazer uso do Conteúdo Licenciado, que pode incluir o Conteúdo Licenciado que nós incluímos em
              seu design entregável, exceto se utilizado como parte vinculada ao Projeto de Design entregue.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Conteúdo da Empresa</h2>
            <p>
              O Serviço e seu conteúdo original (excluindo Conteúdo do Cliente e Conteúdo Licenciado que possuem regras
              específicas conforme detalhado acima), características e funcionalidades são e continuarão sendo
              propriedade exclusiva da Jubarte Design e seus licenciadores. O Serviço é protegido por direitos autorais,
              marcas registradas e outras leis de países estrangeiros. Nossas marcas registradas e identidade visual não
              podem ser usadas em conexão com qualquer produto ou serviço sem o consentimento prévio por escrito da
              Jubarte Design.
            </p>
            <p>
              Além disso, o conteúdo encontrado no ou através deste Serviço ("<b>Conteúdo da Empresa</b>") é propriedade
              da Jubarte Design ou usado com permissão. Você não pode distribuir, modificar, transmitir, reutilizar,
              baixar, repassar, copiar ou usar o Conteúdo da Empresa, seja no todo ou em parte, para fins comerciais ou
              para ganho pessoal, sem a expressa autorização prévia por escrito da Jubarte Design.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Usos Proibidos</h2>
            <p>
              Você pode utilizar o Serviço somente para fins lícitos e de acordo com o Contrato. Você concorda em não
              utilizar o Serviço:
            </p>
            <p className="jd-terms-list-item">
              (a) De qualquer forma que viole qualquer lei ou regulamento nacional ou internacional aplicável;
            </p>
            <p className="jd-terms-list-item">
              (b) Com o propósito de explorar, prejudicar ou tentar explorar ou prejudicar menores de qualquer forma,
              expondo-os a conteúdo impróprio ou de outra forma;
            </p>
            <p className="jd-terms-list-item">
              (c) Para transmitir, ou obter o envio de qualquer material publicitário ou promocional, incluindo qualquer
              "spam" (mensagens indesejadas e não solicitadas), "correntes", ou qualquer outra solicitação semelhante;
            </p>
            <p className="jd-terms-list-item">
              (d) Para personificar ou tentar personificar a Empresa, um funcionário da Empresa, outro usuário, ou
              qualquer outra pessoa ou entidade;
            </p>
            <p className="jd-terms-list-item">
              (e) De qualquer forma que viole os direitos de terceiros, ou de qualquer forma ilegal, ameaçadora,
              fraudulenta ou prejudicial, ou em conexão com qualquer propósito ou atividade ilegal, fraudulenta ou
              prejudicial; e
            </p>
            <p className="jd-terms-list-item">
              (f) Para se envolver em qualquer outra conduta que restrinja ou iniba o uso ou gozo do Serviço por
              qualquer pessoa, ou que, conforme determinado por nós, possa prejudicar ou ofender a Companhia ou os
              usuários do Serviço ou expô-los à perdas e danos.
            </p>
            <p>Adicionalmente, você concorda em não:</p>
            <p className="jd-terms-list-item">
              (a) Usar o Serviço de qualquer maneira que possa incapacitar, sobrecarregar, danificar ou prejudicar o
              Serviço ou interferir com o uso do Serviço por qualquer outra parte, incluindo sua capacidade de se
              envolver em atividades em tempo real através do Serviço;
            </p>
            <p className="jd-terms-list-item">(b) Compartilhar nomes de usuário ou senhas para acessar o Site;</p>
            <p className="jd-terms-list-item">
              (c) Abusar do Serviço ou de nossa equipe de qualquer forma, incluindo o uso de nossos Serviços para fins
              ilegais;
            </p>
            <p className="jd-terms-list-item">
              (d) Acessar ou usar os Serviços a fim de monitorar sua disponibilidade, desempenho ou funcionalidade para
              fins competitivos;
            </p>
            <p className="jd-terms-list-item">
              (e) Realizar engenharia reversa (exceto na medida especificamente permitida por lei estatutária),
              descompilar, desmontar ou de outra forma tentar descobrir código fonte, código objeto ou estruturas
              subjacentes, ideias ou algoritmos do Serviço;
            </p>
            <p className="jd-terms-list-item">
              (f) Usar qualquer robô, “spider” ou outro dispositivo automático, processo ou meio para acessar o Serviço
              para qualquer propósito, incluindo o monitoramento ou cópia de qualquer material no Serviço;
            </p>
            <p className="jd-terms-list-item">
              (g) Usar qualquer processo manual para monitorar ou copiar qualquer material no Serviço ou para qualquer
              outro propósito não autorizado sem o nosso consentimento prévio por escrito;
            </p>
            <p className="jd-terms-list-item">
              (h) Modificar, traduzir ou criar trabalhos derivados com base no Serviço;
            </p>
            <p className="jd-terms-list-item">
              (i) Usar qualquer dispositivo, software ou rotina que interfira com o funcionamento adequado do Serviço;
            </p>
            <p className="jd-terms-list-item">
              (j) Introduzir quaisquer vírus, cavalos de Tróia, “worms”, bombas lógicas ou outro material que seja
              malicioso ou tecnologicamente nocivo;
            </p>
            <p className="jd-terms-list-item">
              (k) Tentar obter acesso não autorizado, interferir, danificar ou interromper qualquer parte do Serviço, o
              servidor no qual o Serviço está armazenado, ou qualquer servidor, computador ou banco de dados conectado
              ao Serviço;
            </p>
            <p className="jd-terms-list-item">
              (l) Atacar o Serviço através de um ataque de negação de serviço ou de um ataque distribuído de negação de
              serviço;
            </p>
            <p className="jd-terms-list-item">
              (m) Tomar qualquer ação que possa danificar ou falsificar a avaliação da Companhia; e
            </p>
            <p className="jd-terms-list-item">
              (n) De qualquer outra forma, tentar interferir no funcionamento adequado do Serviço.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Uso Restrito para Menores de Idade</h2>
            <p>
              O Serviço destina-se apenas ao acesso e utilização por indivíduos com pelo menos dezoito (18) anos de
              idade. Ao acessar ou usar o Serviço, você garante e declara que tem pelo menos dezoito (18) anos de idade
              e possui plena autoridade, direito e capacidade para celebrar este Contrato e cumprir todos os termos e
              condições dos Termos. Se você não tiver pelo menos dezoito (18) anos de idade, você está proibido tanto do
              acesso quanto da utilização do Serviço.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Contas de Usuários</h2>
            <p>
              Ao criar uma conta conosco, você garante que é maior de 18 anos de idade e que as informações que nos
              fornece ("<b>Dados Cadastrais</b>") são precisas, completas e atuais em todos os momentos. Informações
              imprecisas, incompletas ou obsoletas podem resultar no encerramento imediato de sua conta no Serviço.
            </p>
            <p>
              Você é responsável por manter a confidencialidade de sua conta e senha, incluindo, mas não se limitando à
              restrição de acesso ao seu computador e conta. Você concorda em aceitar a responsabilidade por toda e
              qualquer atividade ou ação que ocorra sob sua conta, senha ou ambos, quer sua senha esteja com nosso
              Serviço ou com um serviço de terceiros. Você deve nos notificar imediatamente ao tomar conhecimento de
              qualquer violação de segurança ou uso não autorizado de sua conta.
            </p>
            <p>
              Cada pessoa que usa o Serviço deve ter um nome de usuário e senha separados. Como mencionado na seção Usos
              Proibidos acima, nomes de usuário e senhas para acessar o Serviço não podem ser compartilhados.
            </p>
            <p>
              Você não pode usar como nome de usuário o nome de outra pessoa ou entidade ou que não esteja legalmente
              disponível para uso, um nome ou marca que esteja sujeito a quaisquer direitos de outra pessoa ou entidade
              que não seja você, sem a devida autorização. Você não pode usar como nome de usuário qualquer nome que
              seja ofensivo, vulgar ou obsceno.
            </p>
            <p>
              Reservamo-nos o direito de recusar serviço, encerrar contas, remover ou editar conteúdo, ou cancelar
              pedidos, a nosso exclusivo critério.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Informações Confidenciais</h2>
            <p>
              Para fins deste Contrato, as informações não públicas ou proprietárias, incluindo, sem limitação,
              informações relacionadas a negócios atuais ou futuros, produtos e serviços, pesquisa, imagens,
              desenvolvimento, detalhes e especificações de design e planos de marketing são consideradas confidenciais
              ("<b>Informações Confidenciais</b>").
            </p>
            <p>
              Você pode nos revelar suas Informações Confidenciais durante o curso de nosso relacionamento, nós
              concordamos em manter sigilo das mesmas e não as revelaremos a terceiros, exceto conforme aprovado ou
              orientado por escrito por você. Não usaremos suas Informações Confidenciais para nenhum outro fim que não
              seja para a prestação do Serviço previsto neste Contrato. Limitaremos o acesso às suas Informações
              Confidenciais somente aos funcionários, diretores, gerentes, conselheiros, prestadores de serviço,
              representantes e agentes que estejam envolvidos na prestação do Serviço a você. Seremos responsáveis
              perante você por qualquer violação desta disposição por nossos funcionários, diretores, gerentes,
              conselheiros, prestadores de serviço, representantes e agentes.
            </p>
            <p>
              Da mesma forma, poderemos divulgar a você nossas Informações Confidenciais durante o curso de nosso
              relacionamento. Você concorda em manter a confidencialidade e não divulgar a terceiros nenhuma de nossas
              Informações Confidenciais, exceto conforme aprovado ou orientado por escrito por nós, e não utilizará
              nossas Informações Confidenciais para nenhum fim, exceto conforme permitido por este Contrato. Você
              limitará o acesso às nossas Informações Confidenciais somente aos funcionários, diretores, gerentes,
              conselheiros, prestadores de serviço, representantes e agentes aos quais seja necessário divulgar nossas
              Informações Confidenciais. Você será responsável por qualquer violação desta disposição por seus
              funcionários, diretores, conselheiros, prestadores de serviço, representantes e agentes.
            </p>
            <p>
              Não obstante qualquer disposição em contrário neste Contrato, o seguinte não é considerado Informação
              Confidencial:
            </p>
            <p className="jd-terms-list-item">
              (a) informações que eram de domínio público no momento de sua divulgação ou que tenham entrado em domínio
              público sem violação deste Contrato;
            </p>
            <p className="jd-terms-list-item">
              (b) informações que já estavam na posse de uma parte no momento da divulgação;
            </p>
            <p className="jd-terms-list-item">
              (c) informações que são desenvolvidas independentemente por uma parte sem violar este Contrato; ou
            </p>
            <p className="jd-terms-list-item">
              (d) informações que se tornam conhecidas por uma parte, sem restrições, de uma fonte terceira que não
              esteja envolvida direta ou indiretamente com uma violação deste.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Direitos de Portfólio</h2>
            <p>
              Como resultado deste Contrato, você nos concede uma licença mundial limitada, não exclusiva, não
              publicável e livre de royalties para usar, publicar e exibir quaisquer produtos que desenvolvemos em
              conexão com os Serviços para fins de marketing e publicidade (a "<b>Licença de Direitos de Portfólio</b>
              ").
            </p>
            <p>
              Você pode revogar a Licença de Direitos de Portfólio a qualquer momento, enviando notificação por escrito
              para o nosso{" "}
              <a
                href="mailto:contact@jubarte.design?subject=Revogar Licença de Direitos de Portfólio"
                className="jd-terms-mailto"
              >
                e-mail
              </a>
              . Se você revogar a Licença de Direitos de Portfólio, nós deixaremos de utilizar seus produtos para fins
              de marketing e publicidade, entretanto seus produtos podem continuar a existir em outros lugares online,
              como onde os produtos foram utilizados por outros de acordo com a Licença de Direitos de Portfólio.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Política de Direitos Autorais</h2>
            <p>
              Nós respeitamos os direitos de propriedade intelectual de terceiros e levamos a sério as reclamações de
              violação de direitos autorais. É nossa política responder a qualquer reclamação de que o Conteúdo da
              Empresa publicado no Serviço infringe os direitos autorais ou outros direitos de propriedade intelectual
              ("<b>Infração</b>") de qualquer pessoa ou entidade.
            </p>
            <p>
              Se você é um proprietário de direitos autorais, ou autorizado em nome de um, e você acredita que o
              trabalho protegido por direitos autorais foi copiado de uma forma que constitua violação de direitos
              autorais, por favor envie sua reivindicação para o nosso{" "}
              <a href="mailto:contact@jubarte.design?subject=Infração de Copyright" className="jd-terms-mailto">
                e-mail
              </a>
              , com o assunto em questão: "Violação de Direitos Autorais" e inclua em sua reivindicação uma descrição
              detalhada da suposta Infração. A notificação da suposta Infração de direitos autorais deve incluir o
              máximo de detalhes possíveis para nos permitir identificar os fatos ou circunstâncias, incluindo, quando
              possível:
            </p>
            <p className="jd-terms-list-item">
              (a) Uma Assinatura física ou eletrônica do proprietário (ou pessoa autorizada a agir em nome do
              proprietário) dos direitos autorais alegadamente infringidos;
            </p>
            <p className="jd-terms-list-item">
              (b) A identificação específica de cada trabalho protegido por direitos autorais alegadamente infringido;
            </p>
            <p className="jd-terms-list-item">
              (c) Uma descrição de onde o material que se acredita estar infringindo está localizado no Site ou no
              Serviço (por favor, seja o mais detalhado possível e forneça uma URL para nos ajudar a localizar o
              material que você está relatando);
            </p>
            <p className="jd-terms-list-item">
              (d) Informações de contato da parte reclamante, como nome completo, endereço, número de telefone e
              endereço de e-mail;
            </p>
            <p className="jd-terms-list-item">
              (e) Uma declaração de que a parte reclamante acredita de boa fé que o uso da(s) obra(s) da maneira
              reclamada não é autorizado pelo proprietário dos direitos autorais, seu agente, ou pela lei; e
            </p>
            <p className="jd-terms-list-item">
              (f) Uma declaração de que as informações na notificação são precisas, e que a parte reclamante é a titular
              do direito supostamente infringido, ou agente do proprietário.
            </p>
            <p>
              Você pode ser responsabilizado por danos (incluindo custos e honorários advocatícios) por declarações
              falsas ou reclamações de má fé sobre a violação de qualquer Conteúdo encontrado em e/ou através do Serviço
              sobre seus direitos autorais.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Relatório de Erros e Feedback</h2>
            <p>
              Você pode nos fornecer diretamente através do nosso{" "}
              <a href="mailto:contact@jubarte.design?subject=Feedback" className="jd-terms-mailto">
                e-mail
              </a>
              , via Serviço, via Site, ou via sites e ferramentas de terceiros, informações e feedback sobre erros,
              sugestões de melhorias, ideias, problemas, depoimentos, reclamações e outros assuntos relacionados ao
              nosso Serviço ("<b>Feedback</b>").
            </p>
            <p>Você reconhece e concorda que:</p>
            <p className="jd-terms-list-item">
              (a) você não deve reter, adquirir ou fazer valer qualquer direito de propriedade intelectual ou outro
              direito, título ou interesse no ou para o Feedback;
            </p>
            <p className="jd-terms-list-item">
              (b) a Jubarte Design pode ter ideias em desenvolvimento similares ao Feedback;
            </p>
            <p className="jd-terms-list-item">
              (c) o Feedback não contém informações confidenciais ou informações proprietárias de você ou de terceiros;
              e
            </p>
            <p className="jd-terms-list-item">
              (d) a Jubarte Design não tem qualquer obrigação de confidencialidade com relação ao Feedback.
            </p>
            <p>
              Caso a transferência de propriedade para o Feedback não seja possível devido às leis obrigatórias
              aplicáveis, você concede à Jubarte Design e suas afiliadas um direito exclusivo, transferível, mundial,
              irrevogável, gratuito, sub-licenciável, ilimitado e perpétuo de usar (incluindo copiar, modificar, criar
              trabalhos derivados, publicar, distribuir e comercializar) o Feedback de qualquer forma e para qualquer
              propósito.
            </p>
            <p>Os sites e ferramentas de terceiros mencionados acima incluem o seguinte:</p>
            <p>
              <b>Rollbar</b>
            </p>
            <p>
              A Rollbar é um serviço de rastreamento de erros fornecido pela Rollbar Inc. Saiba mais aqui:{" "}
              <a
                href="https://docs.rollbar.com/docs/privacy-policy"
                target="_blank"
                rel="nofollow noopener noreferrer"
                className="jd-terms-link"
              >
                https://docs.rollbar.com/docs/privacy-policy
              </a>
              .
            </p>
            <p>
              <b>Sentry</b>
            </p>
            <p>
              O Sentry é uma solução open-source de rastreamento de erros fornecida pela Functional Software Inc. Mais
              informações estão disponíveis aqui:{" "}
              <a
                href="https://sentry.io/privacy/"
                target="_blank"
                rel="nofollow noopener noreferrer"
                className="jd-terms-link"
              >
                https://sentry.io/privacy/
              </a>
              .
            </p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Analytics</h2>
            <p>Podemos utilizar serviços de terceiros para monitorar e analisar o uso do nosso Serviço.</p>
            <p>
              <b>Google Analytics</b>
            </p>
            <p>
              O Google Analytics é um serviço de análise web fornecido pelo Google que rastreia e reporta o tráfego do
              Site. O Google utiliza os dados coletados para rastrear e monitorar o uso do nosso Serviço. Estes dados
              são compartilhados com outros serviços do Google. O Google pode utilizar os dados coletados para
              contextualizar e personalizar os anúncios de sua própria rede de publicidade.
            </p>
            <p>
              Para obter mais informações sobre as práticas de privacidade do Google, visite a página do Google Privacy
              Terms:{" "}
              <a
                href="https://policies.google.com/privacy?hl=en"
                target="_blank"
                rel="nofollow noopener noreferrer"
                className="jd-terms-link"
              >
                https://policies.google.com/privacy?hl=en
              </a>
              .
            </p>
            <p>
              Nós também encorajamos você a rever a política do Google para salvaguardar seus dados:{" "}
              <a
                href="https://support.google.com/analytics/answer/6004245"
                target="_blank"
                rel="nofollow noopener noreferrer"
                className="jd-terms-link"
              >
                https://support.google.com/analytics/answer/6004245
              </a>
              .
            </p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Links para outros Websites</h2>
            <p>
              Nosso Serviço pode conter links para sites ou serviços de terceiros que não são de propriedade da Jubarte
              Design ou não são controlados por ela.
            </p>
            <p>
              A Jubarte Design não tem controle sobre, e não assume responsabilidade pelo conteúdo, políticas de
              privacidade, ou práticas de quaisquer sites ou serviços de terceiros. Não garantimos as ofertas de
              qualquer uma dessas entidades/indivíduos ou seus websites.
            </p>
            <p>
              VOCÊ RECONHECE E CONCORDA QUE A EMPRESA NÃO SERÁ RESPONSÁVEL OU CO-RESPONSÁVEL, DIRETA OU INDIRETAMENTE,
              POR QUALQUER DANO OU PERDA CAUSADA OU ALEGADA CAUSADA POR OU EM CONEXÃO COM O USO OU DEPENDÊNCIA DE
              QUALQUER CONTEÚDO, BENS OU SERVIÇOS DISPONÍVEIS EM OU ATRAVÉS DE QUAISQUER DESSES SITES OU SERVIÇOS DE
              TERCEIROS.
            </p>
            <p>
              NÓS ACONSELHAMOS FORTEMENTE QUE VOCÊ LEIA OS TERMOS DE USO E POLÍTICAS DE PRIVACIDADE DE QUAISQUER SITES
              OU SERVIÇOS DE TERCEIROS QUE VOCÊ VISITE.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Isenção de Garantia</h2>
            <p>
              Nós representamos e garantimos que você receberá uma boa e válida licença de uso para todos os produtos
              entregues, livre e desembaraçada de todos os ônus e encargos de qualquer tipo, exceto Conteúdo Licenciado
              e outros materiais pré-existentes, que podem estar sujeitos a termos e restrições adicionais.
            </p>
            <p>
              TESTES SERVIÇOS SÃO FORNECIDOS PELA EMPRESA "COMO ESTÃO" E "CONFORME DISPONIBILIDADE". A EMPRESA NÃO FAZ
              REPRESENTAÇÕES OU GARANTIAS DE QUALQUER TIPO, EXPRESSAS OU IMPLÍCITAS, QUANTO AO FUNCIONAMENTO DE SEUS
              SERVIÇOS, OU DAS INFORMAÇÕES, CONTEÚDOS OU MATERIAIS NELES INCLUÍDOS.
            </p>
            <p>
              NEM A EMPRESA NEM QUALQUER PESSOA ASSOCIADA À EMPRESA FAZ QUALQUER GARANTIA OU REPRESENTAÇÃO COM RESPEITO
              À COMPLETUDE, SEGURANÇA, CONFIABILIDADE, QUALIDADE, PRECISÃO OU DISPONIBILIDADE DOS SERVIÇOS. SEM LIMITAR
              O PRECEDENTE, NEM A EMPRESA NEM QUALQUER PESSOA ASSOCIADA À EMPRESA REPRESENTA OU GARANTE QUE OS SERVIÇOS,
              SEU CONTEÚDO OU QUAISQUER SERVIÇOS OU ITENS OBTIDOS ATRAVÉS DOS SERVIÇOS SERÃO PRECISOS, CONFIÁVEIS,
              LIVRES DE ERROS OU ININTERRUPTOS, QUE OS DEFEITOS SERÃO CORRIGIDOS, QUE OS SERVIÇOS OU O SERVIDOR QUE OS
              DISPONIBILIZA ESTÃO LIVRES DE VÍRUS OU OUTROS COMPONENTES NOCIVOS OU QUE OS SERVIÇOS OU QUAISQUER SERVIÇOS
              OU ITENS OBTIDOS ATRAVÉS DOS SERVIÇOS IRÃO DE OUTRA FORMA ATENDER ÀS SUAS NECESSIDADES OU EXPECTATIVAS.
            </p>
            <p>
              A EMPRESA SE ISENTA DE TODAS AS GARANTIAS DE QUALQUER TIPO, SEJAM EXPRESSAS OU IMPLÍCITAS, ESTATUTÁRIAS OU
              OUTRAS, INCLUINDO MAS NÃO LIMITADAS A QUAISQUER GARANTIAS DE COMERCIABILIDADE, ADEQUAÇÃO, NÃO VIOLAÇÃO DE
              PROPRIEDADE INTELECTUAL E OUTRAS VIOLAÇÕES DE DIREITOS, SEJAM ELAS ORAIS OU ESCRITAS, SEJAM ELAS
              DECORRENTES DE LEI, CURSO DE NEGOCIAÇÃO, CURSO DE EXECUÇÃO, USO, COMÉRCIO OU OUTRAS.
            </p>
            <p>
              O ACIMA EXPOSTO NÃO AFETA QUAISQUER GARANTIAS QUE NÃO POSSAM SER EXCLUÍDAS OU LIMITADAS PELA LEGISLAÇÃO
              APLICÁVEL.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Limitação de Responsabilidade Civil</h2>
            <p>
              EXCETO QUANDO PROIBIDO POR LEI, VOCÊ MANTERÁ A NÓS E A NOSSOS DIRETORES, CONSELHEIROS, FUNCIONÁRIOS E
              AGENTES ISENTOS DE RESPONSABILIDADE POR QUALQUER DANO INDIRETO, PUNITIVO, ESPECIAL, INCIDENTAL OU
              CONSEQÜENTE, SEJA QUAL FOR SUA ORIGEM (INCLUINDO HONORÁRIOS ADVOCATÍCIOS E TODOS OS CUSTOS E DESPESAS
              RELACIONADAS AO LITÍGIO E À ARBITRAGEM, OU NO JULGAMENTO OU EM RECURSO, SE HOUVER, QUER SEJA OU NÃO
              INSTITUÍDO LITÍGIO OU ARBITRAGEM), SEJA NO USO OU NA INCAPACIDADE DE USAR O SITE, UMA AÇÃO DE CONTRATO,
              NEGLIGÊNCIA OU OUTRA AÇÃO ILÍCITA, DECORRENTE DE OU EM CONEXÃO COM ESTE CONTRATO, INCLUINDO, SEM
              LIMITAÇÃO, QUALQUER RECLAMAÇÃO POR LUCROS CESSANTES, PERDA DE RECEITAS, PERDA DE DADOS, PERDAS
              FINANCEIRAS, DANOS PESSOAIS OU DANOS MATERIAIS, DECORRENTES DESTE CONTRATO E QUALQUER VIOLAÇÃO POR VOCÊ DE
              QUALQUER LEI FEDERAL, ESTADUAL OU LOCAL, ESTATUTOS, REGRAS OU REGULAMENTOS, MESMO QUE A EMPRESA TENHA SIDO
              PREVIAMENTE AVISADA DA POSSIBILIDADE DE TAIS DANOS. EXCETO CONFORME PROIBIDO POR LEI, SE HOUVER
              RESPONSABILIDADE POR PARTE DA EMPRESA, ESTA SERÁ LIMITADA AO VALOR PAGO PELOS PRODUTOS E/OU SERVIÇOS, E
              SOB NENHUMA CIRCUNSTÂNCIA HAVERÁ DANOS CONSEQÜENTES OU PUNITIVOS. ESTE PARÁGRAFO NÃO AFETA QUALQUER
              RESPONSABILIDADE QUE NÃO POSSA SER EXCLUÍDA OU LIMITADA NOS TERMOS DA LEI APLICÁVEL.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Indenização</h2>
            <p>
              O CLIENTE CONCORDA EM DEFENDER, INDENIZAR E ISENTAR-NOS DE QUAISQUER RECLAMAÇÕES, RESPONSABILIDADES,
              DANOS, PERDAS E DESPESAS, INCLUINDO, SEM LIMITAÇÃO, HONORÁRIOS E CUSTOS RAZOÁVEIS DE ADVOGADOS,
              DECORRENTES OU DE QUALQUER FORMA RELACIONADOS AO CONTEÚDO DO CLIENTE OU AO USO DO SERVIÇO OU DE QUAISQUER
              ENTREGÁVEIS. O CLIENTE DEVE COOPERAR CONFORME EXIGIDO POR NÓS NA DEFESA DE QUALQUER REIVINDICAÇÃO.
              RESERVAMO-NOS O DIREITO DE ASSUMIR A EXCLUSIVA DEFESA E CONTROLE DE QUALQUER MATÉRIA SUJEITA A INDENIZAÇÃO
              PELO CLIENTE, E O CLIENTE NÃO RESOLVERÁ, EM NENHUMA HIPÓTESE, QUALQUER RECLAMAÇÃO SEM O NOSSO PRÉVIO
              CONSENTIMENTO POR ESCRITO.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Jurisdição Proibida</h2>
            <p>
              Você não usará ou acessará nosso Serviço se estiver localizado em qualquer jurisdição na qual a prestação
              de nosso Serviço seja proibida pelas leis Canadenses, Americanas ou outras (uma "
              <b>Jurisdição Proibida</b>") e não fornecerá acesso a nosso Serviço a qualquer governo, entidade ou
              indivíduo localizado em qualquer Jurisdição Proibida. Você confirma que:
            </p>
            <p className="jd-terms-list-item">
              (a) não está nomeado em nenhuma lista do governo Canadense ou Americano de pessoas ou entidades proibidas
              de transação com qualquer pessoa Canadense ou Americana;
            </p>
            <p className="jd-terms-list-item">
              (b) você não é um cidadão ou uma empresa registrada em qualquer jurisdição proibida;
            </p>
            <p className="jd-terms-list-item">
              (c) você não permitirá que Usuários Autorizados acessem ou usem nosso Serviço em violação a qualquer
              jurisdição Canadense, Americana ou outros embargos, proibições ou restrições de exportação; e
            </p>
            <p className="jd-terms-list-item">
              (d) você cumprirá todas as leis referentes à transmissão de dados exportados do país em que você (ou seus
              Usuários Autorizados) está localizado para o Canadá e os Estados Unidos.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Cessão de Direitos</h2>
            <p>
              Este Contrato e os direitos e obrigações aqui contidos são pessoais para você. Você não poderá ceder ou
              transferir este Contrato ou quaisquer de seus direitos ou obrigações aqui descritas, sem nosso
              consentimento prévio por escrito. Nós poderemos ceder livremente este Contrato, inclusive, sem limitação,
              em conexão com uma fusão, aquisição, falência, reorganização ou venda de alguns ou todos os nossos ativos
              ou ações.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Rescisão</h2>
            <p>
              Podemos rescindir este Contrato a qualquer momento mediante aviso prévio, caso o cliente não cumpra ou
              quebre este Contrato. Na expiração ou encerramento de sua conta ou Assinatura de um Serviço, todos os
              direitos previstos no presente Contrato relativos a tal Serviço serão imediatamente rescindidos. Você
              perderá todo o acesso ao Serviço aplicável, incluindo o acesso à sua conta e ao Conteúdo do Cliente ou
              outros arquivos. Se nós rescindirmos o Contrato por sua violação, quaisquer licenças de Conteúdo
              Licenciado serão rescindidas.
            </p>
            <p>
              O presente Contrato expirará e terminará na expiração ou no encerramento de sua conta ou Assinatura do
              Serviço. Você pode cancelar seu Serviço ou fazer mudança de seu Plano a qualquer momento através de nosso
              website. Se você cancelar (ou fizer mudanças) de um Serviço já pago, deverá continuar a pagar pelo
              restante do prazo do seu Plano (mensal, trimestral, anual) e não terá direito a um reembolso, com exceção
              do definido na seção Reembolsos acima.
            </p>
            <p>
              As obrigações de confidencialidade previstas neste Contrato subsistirão por cinco (5) anos após o término
              deste Contrato.
            </p>
            <p>
              Todas as disposições dos Termos que, por sua natureza, devam sobreviver à rescisão deverão sobreviver à
              rescisão, incluindo, sem limitação, direitos acumulados ao pagamento, obrigações de confidencialidade,
              cláusulas de propriedade, isenções de garantia, indenização e limitações de responsabilidade.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Legislação aplicável</h2>
            <p>
              As leis da província da Colúmbia Britânica e as leis canadenses aplicáveis na Colúmbia Britânica regerão
              qualquer disputa ("<b>Disputa</b>"), causa de ação ou reclamação decorrente do presente Contrato ou do uso
              de nosso Serviço, inclusive contra qualquer Parte da Jubarte Design sem considerar qualquer escolha de
              lei, conflitos de leis ou outros princípios que resultariam na aplicação das leis ou regulamentos de
              qualquer outra jurisdição.
            </p>
            <p>
              Se você violar este Contrato ou violar nossos direitos ou os direitos de outra pessoa, podemos pedir a um
              tribunal que o impeça; se apresentarmos uma reclamação contra você, você nos reembolsará por nossos
              honorários legais razoáveis por essa reclamação.
            </p>
            <p>
              Se você tiver qualquer tipo de disputa conosco, o meio exclusivo de resolvê-la será por arbitragem
              confidencial e vinculante perante um único árbitro escolhido por você e pela Jubarte Design. Você nos
              avisará sobre sua Disputa por escrito.
            </p>
            <p>
              Se não decidirmos juntos sobre um árbitro dentro de quinze (15) dias após recebermos essa notificação,
              ambos concordamos em solicitar ao British Columbia International Commercial Arbitration Centre (BCICAC)
              que nomeie um para nós.
            </p>
            <p>
              A arbitragem será realizada em Vancouver, British Columbia, sob a Lei de Arbitragem (British Columbia), e
              seguirá as Regras de Arbitragem Comercial Interna do BCICAC.
            </p>
            <p>
              O árbitro terá o direito de decidir como os custos devem ser divididos entre nós. O árbitro terá o direito
              de aceitar qualquer tipo de prova que julgar apropriada e terá o direito de fazer qualquer sentença que
              considerar justa e equitativa, baseada em princípios legais e equitativos, incluindo dar uma ordem tal
              como uma injunção (para impedir que um de nós faça algo) ou uma ordem para que você ou nós paguemos danos
              ao outro. A sentença do árbitro pode ser inscrita no registro da Suprema Corte da Colúmbia Britânica em
              Vancouver, Columbia Britânica, e executada da mesma forma que uma ordem judicial, se o tribunal da
              jurisdição executória o permitir.
            </p>
            <p>JUBARTE DESIGN E VOCÊ CONCORDAM QUE:</p>
            <p className="jd-terms-list-item">
              (A) QUALQUER PROCESSO DE RESOLUÇÃO DE DISPUTA SERÁ CONDUZIDO SOMENTE EM UMA BASE INDIVIDUAL E NÃO EM UMA
              AÇÃO DE CLASSE, CONSOLIDADA OU REPRESENTATIVA;
            </p>
            <p className="jd-terms-list-item">(B) CADA UM RENUNCIA A QUALQUER DIREITO A UM JULGAMENTO POR JÚRI;</p>
            <p className="jd-terms-list-item">
              (C) QUALQUER CAUSA DE AÇÃO OU RECLAMAÇÃO DECORRENTE OU RELACIONADA A ESTES TERMOS DEVE SER INICIADA DENTRO
              DE UM (1) ANO APÓS O VENCIMENTO DA CAUSA DA AÇÃO, CASO CONTRÁRIO, TAL CAUSA DE AÇÃO OU RECLAMAÇÃO É
              PERMANENTEMENTE IMPEDIDA.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Mudanças no Serviço</h2>
            <p>
              Nós nos reservamos o direito de retirar ou alterar nosso Serviço, e qualquer serviço ou material que
              fornecemos via Site, a nosso exclusivo critério, sem aviso prévio. Não seremos responsáveis se, por
              qualquer motivo, todo ou qualquer parte do Serviço estiver indisponível a qualquer momento ou por qualquer
              período. De tempos em tempos, poderemos restringir o acesso a algumas partes do Serviço, ou a todo o
              Serviço, a usuários, incluindo usuários registrados.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Emendas aos Termos</h2>
            <p>
              Nós podemos alterar os Termos a qualquer momento, publicando os termos alterados neste Site. É sua
              responsabilidade revisar estes Termos periodicamente.
            </p>
            <p>
              Seu uso contínuo do Site após a publicação dos Termos revisados significa que você aceita e concorda com
              as alterações. Espera-se que você verifique esta página frequentemente para estar ciente de quaisquer
              alterações, pois elas são vinculativas para você.
            </p>
            <p>
              Ao continuar a acessar ou usar nosso Serviço após qualquer revisão se tornar efetiva, você concorda em
              estar vinculado aos termos revisados. Se você não concordar com os novos termos, você não está mais
              autorizado a usar o Serviço.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Renúncia e Severabilidade</h2>
            <p>
              Nenhuma renúncia pela Empresa de qualquer termo ou condição estabelecida nos Termos será considerada uma
              renúncia adicional ou contínua de tal termo ou condição ou uma renúncia de qualquer outro termo ou
              condição. Qualquer falha da Empresa em fazer valer um direito ou provisão sob Termos não constituirá uma
              renúncia de tal direito ou provisão.
            </p>
            <p>
              Se qualquer disposição dos Termos for considerada inválida, ilegal ou inexequível por qualquer razão por
              um tribunal ou outra jurisdição competente, as demais disposições dos Termos continuarão em pleno vigor e
              efeito, e a disposição inválida, ilegal ou inexequível será substituída por uma disposição ou disposições
              válidas, legais e executórias que mais se aproximem da intenção das partes subjacentes à disposição
              inválida, ilegal ou inexequível.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Força Maior</h2>
            <p>
              Se formos incapazes de cumprir qualquer obrigação sob este Contrato devido a qualquer motivo além de nosso
              controle razoável, incluindo, mas não se limitando a, pandemia ou ampla propagação de doenças infecciosas;
              quaisquer leis, ordens, regras, regulamentos, atos ou restrições de qualquer governo ou órgão ou
              autoridade governamental, civil ou militar, incluindo as ordens e sentenças de tribunais e fechamento do
              governo; clima excepcionalmente severo, relâmpago, inundação, terremoto; incêndio, explosão; atos de
              guerra, atos de terrorismo, desordem civil, sabotagem; invasão alienígena, ataque de zumbis; greves,
              bloqueios ou interrupções de trabalho (envolvendo ou não nossos funcionários), atos de governo, perda ou
              problemas com telecomunicações, serviços públicos ou outros serviços de terceiros, e ataques hostis à
              internet ou à rede (cada um, um "<b>Evento de Força Maior</b>"), não teremos qualquer responsabilidade
              para com o cliente por tal falha de desempenho; desde que, no entanto, retomemos o desempenho prontamente
              após a remoção das circunstâncias que constituem o Evento de Força Maior.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Reconhecimento</h2>
            <p>
              AO UTILIZAR O SERVIÇO OU OUTROS SERVIÇOS FORNECIDOS POR NÓS, VOCÊ RECONHECE QUE LEU ESTES TERMOS DE USO E
              CONCORDA EM FICAR VINCULADO A ELES.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Contato e Notificações</h2>
            <p>
              A Jubarte Design pode avisá-lo através da colocação de um banner no Site. Também podemos entrar em contato
              com você ou seus usuários autorizados através de sua conta Jubarte Design ou informações de contato que
              você fornecer, como e-mail ou telefone, ou através de sua conta em uma ou mais Redes Sociais. Se você
              deixar de manter informações precisas sobre sua conta, tais como informações de contato, você pode não
              receber informações críticas sobre nosso Serviço ou sobre este Contrato.
            </p>
            <p>
              Para qualquer notificação à Jubarte Design que você fizer sob ou em relação a este Contrato, você deve nos
              notificar por{" "}
              <a href="mailto:contact@jubarte.design?subject=Notificação" className="jd-terms-mailto">
                e-mail
              </a>
              .
            </p>
            <p>
              Para feedback, comentários ou solicitações de suporte técnico, por favor, entre em contato no nosso{" "}
              <a href="mailto:contact@jubarte.design?subject=Suporte Técnico" className="jd-terms-mailto">
                e-mail
              </a>{" "}
              ou via chat no nosso Site em{" "}
              <a href="https://jubarte.design" rel="nofollow" className="jd-terms-link">
                https://jubarte.design
              </a>
              .
            </p>
          </div>
          <div className="mb-4">
            <h2 className="h5 mb-3">Contrato Completo</h2>
            <p>
              Este Contrato, incluindo os outros documentos referidos como aplicáveis ao Serviço neste Contrato,
              constitui o acordo completo entre nós em relação ao nosso Serviço e substitui quaisquer acordos prévios
              (orais ou escritos) que possamos ter tido entre nós em relação ao Serviço.
            </p>
          </div>
        </div>
      </div>
    </article>
  </Layout>
)

export default TermsOfUsePage
